/* (A) SPEECH BOX */
.speech {
    /* (A1) FONT & COLORS */
    /* font-size: 1.2em; */
    /* color: #fff; */
    background: #a53d38;
  
    /* (A2) DIMENSIONS */
    padding: 10px;
    border-radius: 10px;
    /* max-width: 600px; */
  }
  
  /* (B) USE ::AFTER TO CREATE THE "CALLOUT" */
  .speech::after {
    display: block; width: 0; content: "";
    border: 15px solid transparent;
  }
   
  /* (C) "CALLOUT" DIRECTIONS */
  .speech.up::after {
    border-bottom-color: #a53d38;
    border-top: 0;
  }
  .speech.down::after {
    border-top-color: #a53d38;
    border-bottom: 0;
  }
  .speech.left::after {
    border-right-color: #EEE;
    border-left: 0;
  }
  .speech.right::after {
    border-left-color: #6ed7b8;
    border-right: 0;
  }
  
  /* (D) POSITION THE CALLOUT */
  .speech {
    position: relative;
    margin-left: 30px;
    margin-right: 30px;
  }
  .speech::after { position: absolute; }
  .speech.up::after {
    top: -15px; left: calc(50% - 15px);
  }
  .speech.down::after {
    bottom: -15px; left: calc(50% - 15px);
  }
  .speech.left::after {
    left: -15px; top: calc(50% - 15px);
  }
  .speech.right::after {
    right: -15px; top: calc(50% - 15px);
  }
  
  /* (X) NOT IMPORTANT */
  /* body{background: url(https://images.unsplash.com/photo-1613917037272-b99f93576703?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MDA3ODk0OTZ8&ixlib=rb-4.0.3&q=85)}
  #cbwrap{background:rgba(255,255,255,.9);width:600px;padding:30px;border-radius:10px}
  *{box-sizing:border-box;font-family:arial,sans-serif}
  body{padding:0;margin:0;border:0;min-height:100vh;display:flex;justify-content:center;align-items:center;background-size:cover;background-position:center;backdrop-filter:blur(10px)}
  #cbtitle{margin:0 0 30px;padding:0;text-transform:uppercase}
  #cbinfo{margin-top:30px;padding-top:15px;border-top:1px solid #ddd;font-size:13px;font-weight:700}
  #cbinfo a{text-decoration:none;padding:5px;color:#fff;background:#a91616} */